/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Layout from '../../components/layout';
import Navbar from '../../components/navbar/navbar';
import MobileNavbar from '../../components/mobile-navbar/mobile-navbar';
import {
  Header,
  LayoutUI,
  Stack,
  HeroImage,
  LeadText,
  Button
} from '../../ui-kit';
import * as styles from './NewsletterSuccess.module.scss';

import HeaderImage from '../../assets/images/newsletter-success/header.jpg';

const NewsletterSuccess = () => (
  <Layout>
    <Navbar color="white" />
    <MobileNavbar color="white" />
    <HeroImage image={HeaderImage} height="small" />
    <LayoutUI narrow>
      <div className={styles.TextWrap}>
        <Stack alignment="center" distribution="center">
          <div className={styles.Title}>
            <Header>Thank you for signing up!</Header>
          </div>
          <LeadText>
            We hope you enjoy the extra inspiration in your inbox. As a gift,
            we'd like you to have our daily intentions e-book, #MayYouBe
            Inspired.
          </LeadText>
          <div className={styles.ButtonWrap}>
            <Button
              color="red"
              href="https://storage.googleapis.com/proj-happiness/assets/MayYouBeInspired.pdf"
            >
              Download
            </Button>
          </div>
        </Stack>
      </div>
    </LayoutUI>
  </Layout>
);

export default NewsletterSuccess;
